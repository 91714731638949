import moment from 'moment'

let gDt = new Date(),
// dpS = gDt.getMonth() === 0 && gDt.getDate() <= 28 ? gDt.getFullYear() -  2 + '-01-01' : gDt.getFullYear() -  1 + '-01-01',
dpS = moment().subtract(365, "days").format('YYYY-MM-DD'),
// dpE = moment().subtract(14, "days").format('YYYY-MM-DD'),
dpE = moment().format('YYYY-MM-DD'),
// dSD = gDt.getMonth() === 0 && gDt.getDate() <= 28 ? moment().subtract(100, "days").format('YYYY-MM-DD') : gDt.getFullYear() + '-01-01';
dSD = moment().subtract(1, "days").format('YYYY-MM-DD');

const inits = {
  modisStartDate: dSD,
  modisEndDate: dpE,
  modisUnconfirm: true,
  tab0:'recent',
  tab:'today',
  dpS,
  dpE
}

const ModisReducer = (s = inits, action) => {
  const {payload} = action
  switch (action.type) {
    case '_MODIS':
      return { ...s, [payload[0]]: payload[1] }

    case 'SET_MODIS_STATE':
      return { ...s,
        modisStartDate: payload[0],
        modisEndDate: payload[1],
        modisUnconfirm: payload[2]
      }
    case '_MODIS_CHECK_MAX':
      if(s.modisEndDate > payload){
        return { ...s,
          modisEndDate: payload,
          modisStartDate: payload < s.modisStartDate ? moment(payload).subtract(14, "days").format('YYYY-MM-DD') : s.modisStartDate
        }
      }else{return s;}

    case '_MODIS_ANS':
      return { ...s,
        modisStartDate: s.dpS > payload[0] ? s.dpS : payload[0],
        modisEndDate: payload[1],
        dpE: s.dpE < payload[1] ? payload[1] : s.dpE
      }

    case '_MODIS_FROM_LINK':
      return { ...s,...payload}

    default:
      return s;
  }
};
export default ModisReducer
