import {subMonths,subYears,format} from 'date-fns'
const inits = {
  swipe: false,
  open1: false,
  hide1: false,
  type1: true,
  open2: false,
  hide2: false,
  date1: !1,
  date2: !1,
  type2: true,
  active: {
    sdate: !1,
    date1: !1,
    date2: !1,
    sdateBbox: null,
    date1Bbox: null,
    date2Bbox: null,
  },
  bbox: null,
  selected:[],
  WMTSCapabilities: !1,
  timelapse: {
    open:false,
    satellite: 'sentinel2',
    frequency:'daily',
    color:true,
    title:'',
    textColor:'ffffff',
    coverageThreshold:50,
    dateStart:`${format(subYears(new Date(),1),'yyyy-MM-dd')}`,
    dateEnd:`${format(new Date(),'yyyy-MM-dd')}`,
    duration:1000,
    cloudCover:20,
    region:`{"type":"FeatureCollection","features":[{"type":"Feature","geometry":{"type":"Polygon","coordinates":[[[140.0220486442171, -2.543797152250833],[140.0220486442171, -2.586583747287662],[140.07380454936848, -2.586583747287662],[140.07380454936848, -2.543797152250833]]]},"properties":{}}]}`,
    gifUrl:!1,
    gifExtent:!1,
    gifOption:[],
    gifDates: [],
    gifImages:!1,
    gifParams: {},
  },
  planet: {
    open: false,
    type: 'monthly',
    period: `${format(subMonths(new Date(),2),'yyyy-MM-01')}`,
    dataSelect:[]
  },
  sentinel2: {
    open: false,
    type: true,
    period: `${format(new Date(),'yyyy-MM-01')}`,
    cc: [0,100],
    dataSelect:[],
    dataList:[],
    selected:[]
  },
  landsat: {
    open: false,
    type: true,
    sensor: 'landsat',
    period: `${format(new Date(),'yyyy-MM-01')}`,
    cc: [0,100],
    dataSelect:[],
    dataList:[],
    selected:[]
  },
  modis: {
    open: false,
    type: true,
    sensor: 'Aqua',
    period: `${format(new Date(),'yyyy-MM-dd')}`,
    dataSelect:[]
  },
  viirs: {
    open: false,
    type: true,
    sensor: 'NOAA20',
    period: `${format(new Date(),'yyyy-MM-dd')}`,
    dataSelect:[]
  },
  esri: {
    open: false,
    dataSelect:[]
  }
}

const AvsReducer = (s = inits, action) => {
  const {payload} = action
  switch (action.type) {
    case '_AVS':
      return { ...s, [payload[0]]: payload[1] }

    case 'SET_AVS':
      return { ...s, ...payload }
    //
    // case 'INIT_AVS':
    //   return { ...s,...inits}

    case '_AVS_OPEN':
      const planet = payload === 'planet' ? true : false
      const sentinel2 = payload === 'sentinel2' ? true : false
      const landsat = payload === 'landsat' ? true : false
      const modis = payload === 'modis' ? true : false
      const viirs = payload === 'viirs' ? true : false
      const esri = payload === 'esri' ? true : false
      const timelapse = payload === 'timelapse' ? true : false
      return { ...s,
        planet: {...s.planet,open:planet},
        sentinel2: {...s.sentinel2,open:sentinel2},
        landsat: {...s.landsat,open:landsat},
        modis: {...s.modis,open:modis},
        viirs: {...s.viirs,open:viirs},
        esri: {...s.esri,open:esri},
        timelapse: {...s.timelapse,open:timelapse},
      }

      case '_AVS_RESET':
        return { ...s,
          [payload]: {...s[payload],...inits[payload]}
        }

      case '_AVS_REFRESH':
        return { ...s,
          [payload]: {...s[payload],
            dataSelect:[]
          }
        }

      case '_AVS_SAT_UPDATE':
        return { ...s,
          [payload[0]]: {...s[payload[0]],[payload[1]]:payload[2]}
        }

      case '_AVS_ACTIVE':
        return { ...s,
          active: {...s.active,[payload[0]]:payload[1]}
        }
      case '_AVS_REMOVE':
        return { ...s,
          active: {...s.active,
            [payload[0]]:payload[1]
          }
        }
      case '_AVS_UPDATE':
        let sdate = s.active.sdate
        let date1 = s.active.date1
        let date2 = s.active.date2
        let sdateBbox = null;
        let date1Bbox = null;
        let date2Bbox = null;

        if (payload[0] === 'sdate') {
          sdate = payload[1];
          sdateBbox = s.bbox;
          if (payload[1]) {
            date1 = date1 === payload[1] ? !1 : date1;
            date2 = date2 === payload[1] ? !1 : date2;
          }
        }
        if (payload[0] === 'date1') {
          date1 = payload[1];
          date1Bbox = s.bbox;
          if (payload[1]) {
            sdate = sdate === payload[1] ? !1 : sdate;
            date2 = date2 === payload[1] ? !1 : date2;
          }
        }
        if (payload[0] === 'date2') {
          date2 = payload[1];
          date2Bbox = s.bbox;
          if (payload[1]) {
            sdate = sdate === payload[1] ? !1 : sdate;
            date1 = date1 === payload[1] ? !1 : date1;
          }
        }
        
        return {
          ...s,
          active: {
            ...s.active,
            sdate,
            date1,
            date2,
            sdateBbox: sdateBbox || s.active.sdateBbox,
            date1Bbox: date1Bbox || s.active.date1Bbox,
            date2Bbox: date2Bbox || s.active.date2Bbox,
          },
        };

      case '_AVS_UPDATE_FROM_LINK':
        return {
          ...s,
          active: {
            ...s.active,
            [payload[0]]: payload[1],
          },
        };

      case '_AVS_SELECTED':
        return { ...s,
          selected: payload
        }

      case '_AVS_FROM_LINK':
        return { ...s, ...payload}

    default:
      return s;
  }
};
export default AvsReducer
