const inits = {
  aoiData: !1,
  aoiLayerResult: !1,
  aoiFeatures: []
}

const AoiReducer = (s = inits, action) => {
  const {payload} = action
  switch (action.type) {
    case '_AOI':
      return { ...s, [payload[0]]: payload[1] }

    case '_AOI_SET_AOI':
      return { ...s,
        aoiData: payload[0],
        aoiLayerResult:payload[1],
        aoiFeatures: payload[2]
      }

    default:
      return s;
  }
};
export default AoiReducer
