import moment from 'moment'
import config from "../config.js"

let gDt = new Date(),
dpS = gDt.getMonth() === 0 && gDt.getDate() <= 28 ? gDt.getFullYear() -  2 + '-01-01' : gDt.getFullYear() -  1 + '-01-01',
dpE = moment().subtract(14, "days").format('YYYY-MM-DD'),
dSD = gDt.getMonth() === 0 && gDt.getDate() <= 28 ? moment().subtract(100, "days").format('YYYY-MM-DD') : gDt.getFullYear() + '-01-01';

function getToolIdLayers(layers) {
  const ids = [];
  layers.map(layer => {
    if (layer.search) {
      ids.push(layer.id);
    }
  });
  return ids;
}

const inits = {
  ...config,
  mapRef: !1,
  sbRef: !1,
  dpS,
  dpE,
  dSD,
  satWMTSCapabilities:!1,
  admins:!1,
  adminsubvils:!1,
  toolIdLayers: [],
  isMobileLayout: !1,
  globalSearchList: [],
  layersLegend: [],
}

const StaticReducer = (s = inits, action) => {
  const {payload} = action
  switch (action.type) {
    case '_STATIC':
      return { ...s, [payload[0]]: payload[1] }

    case '_STATIC_SATELLITE_LAYER_PARSER':
      return { ...s,
        satWMTSCapabilities: payload
      }
    case '_STATIC_SET_TOOL_ID_LAYERS':
      return { ...s,
        toolIdLayers: getToolIdLayers(payload)
      }

    default:
      return s;
  }
};
export default StaticReducer
