import moment from "moment";

const inits = {
  sortBy: 'Date',
  keyword: '',
  datefrom: false,
  dateto: moment().format('YYYY-MM-DD'),
  page: 1,
  total: 0,
  list: [],
  hover: !1,
  hoverMarker: [],
}

const StoryToolReducer = (s = inits, action) => {
  const {payload} = action
  switch (action.type) {
    case '_STORY_TOOL':
      return { ...s, [payload[0]]: payload[1] }

    default:
      return s;
  }
};
export default StoryToolReducer
