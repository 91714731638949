const inits = {
  groupcom: [],
  name: [],
  types: [],
  countries: [],
  provinces: [],
  districts: [],
  subdistricts: [],
  villages: [],
  regions: [],
  rspo: !1,
  rspomember: !1,
  rspocert: !1,
  rspotype: [],
  ispo: !1,
  mspo: !1,
  commodities: [],
  umlid: [],
  feedstock: [],
  buyers: [],
  levels: [],
  l2021ex: false,
  isLorealProjectMills: false,
  lorealProjectMillList: [],
  lorealProjectMills: [],
  peatProp: [0,100],
  forProp:[0,100],
}

const ASearchReducer = (s = inits, action) => {
  const {payload} = action
  switch (action.type) {
    case '_ADVANCED_SEARCH':
      return { ...s, [payload[0]]: payload[1] }

    case 'SET_ADVANCED_SEARCH_FORM':
      let p = {...payload}
      delete p.rpso;
      return { ...s, ...p}

    case 'RESET_ADVANCED_SEARCH_FORM':
      return { ...s, ...inits}

    default:
      return s;
  }
};
export default ASearchReducer