import {_isLoreal} from '../helpers'

const inits = {
  // activeTool: _isLoreal() ? 'millopbufferpolyloreal' : 'oilpalm',
  activeTool: '',
  toolData: {
    gcList: [],
    nameList: [],
    types: [],
    commodities: [],
    districts: [],
    provinces: [],
    countries: [],
    feedstocks: [],
  }
}

const ToolReducer = (s = inits, action) => {
  const {payload} = action
  switch (action.type) {
    case '_TOOL':
      return { ...s, [payload[0]]: payload[1] }

    case 'SET_TOOL_DATA':
      return { ...s,
        toolData: payload ? {...s.toolData,...payload} : s.toolData,
      }

    case '_TOOL_UPDATE_GCLIST':
      return { ...s,
        toolData: {...s.toolData,gcList:payload},
      }

    default:
      return s;
  }
};
export default ToolReducer
