import moment from 'moment'

let gDt = new Date(),
// dpS = gDt.getMonth() === 0 && gDt.getDate() <= 28 ? gDt.getFullYear() -  2 + '-01-01' : gDt.getFullYear() -  1 + '-01-01',
dpS = moment().subtract(365, "days").format('YYYY-MM-DD'),
// dpE = moment().subtract(14, "days").format('YYYY-MM-DD'),
dpE = moment().format('YYYY-MM-DD'),
// dSD = gDt.getMonth() === 0 && gDt.getDate() <= 28 ? moment().subtract(100, "days").format('YYYY-MM-DD') : gDt.getFullYear() + '-01-01';
dSD = moment().subtract(1, "days").format('YYYY-MM-DD');

const inits = {
  viirsStartDate: dSD,
  viirsEndDate: dpE,
  viirsUnconfirm: true,
  tab0:'recent',
  tab:'today',
  dpS,
  dpE
}

const ViirsReducer = (s = inits, action) => {
  const {payload} = action
  switch (action.type) {
    case '_VIIRS':
      return { ...s, [payload[0]]: payload[1] }

    case 'SET_VIIRS_STATE':
      return { ...s,
        viirsStartDate: payload[0],
        viirsEndDate: payload[1],
        viirsUnconfirm: payload[2]
      }
    case '_VIIRS_CHECK_MAX':
      if(s.viirsEndDate > payload){
        return { ...s,
          viirsEndDate: payload,
          viirsStartDate: payload < s.viirsStartDate ? moment(payload).subtract(14, "days").format('YYYY-MM-DD') : s.viirsStartDate
        }
      }else{return s;}

    case '_VIIRS_ANS':
      return { ...s,
        viirsStartDate: s.dpS > payload[0] ? s.dpS : payload[0],
        viirsEndDate: payload[1],
        dpE: s.dpE < payload[1] ? payload[1] : s.dpE
      }

    case '_VIIRS_FROM_LINK':
      return { ...s,...payload}

    default:
      return s;
  }
};
export default ViirsReducer
