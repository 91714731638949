const inits = {
  mapExtent: false,
}

const LoaderReducer = (s = inits, action) => {
  const {payload} = action
  switch (action.type) {
    case '_MAP_EXTENT':
      return { ...s, [payload[0]]: payload[1] }
    case '_MAP_EXTENT_SET_EXTENT':
      return { ...s, mapExtent: payload }

    default:
      return s;
  }
};
export default LoaderReducer
