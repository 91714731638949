const inits = {
  isPanelOpen: true,
}

const dataPanelReducer = (s = inits, action) => {
  const {payload} = action
  switch (action.type) {
    case '_DATA_PANEL':
      return { ...s, [payload[0]]: payload[1] }
    case '_DATA_PANEL_INIT':
      return { ...s, ...payload }
    default:
      return s;
  }
};
export default dataPanelReducer;
