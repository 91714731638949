import moment from 'moment';

let gDt = new Date(),
dpS = moment().subtract(365, "days").format('YYYY-MM-DD'),
dpE = moment().format('YYYY-MM-DD'),
// dSD = moment().subtract(30, "days").format('YYYY-MM-DD');
dSD = moment().format('YYYY-MM-DD');

const inits = {
  sensor: 'VIIRS + MODIS',
  "glad-viirs": {
    group: null,
    single: null
  },
  "glad-modis": {
    group: null,
    single: null
  },
  "glad-viirs+modis": {
    group: null,
    single: null
  },
  startDate: dSD,
  endDate: dpE,
  unconfirmed: true,
  tab0:'recent',
  tab:'today',
  dpS,
  dpE,
  options: !1,
}

const FireAlertReducer = (s = inits, action) => {
  const {payload} = action
  switch (action.type) {
    case '_FIRE_ALERT':
      return { ...s, [payload[0]]: payload[1] }

    case 'SET_FIRE_ALERT_STATE':
      return { ...s,
        startDate: payload[0],
        endDate: payload[1],
        unconfirmed: payload[2]
      }
    case '_FIRE_ALERT_CHECK_MAX':
      if(s.endDate > payload){
        return { ...s,
          endDate: payload,
          startDate: payload < s.startDate ? moment(payload).subtract(14, "days").format('YYYY-MM-DD') : s.startDate
        }
      }else{return s;}

    case '_FIRE_ALERT_ANS':
      return { ...s,
        startDate: s.dpS > payload[0] ? s.dpS : payload[0],
        endDate: payload[1],
        dpE: s.dpE < payload[1] ? payload[1] : s.dpE
      }

    case '_FIRE_ALERT_FROM_LINK':
      return { ...s,...payload}

    case 'SET_VIIRS_DATA':
      return (payload.single) ? { ...s,
        'glad-viirs': {...s['glad-viirs'],single:payload.single}
      }:{ ...s,
        'glad-viirs': {...s['glad-viirs'],group:payload.group}
      }

    case 'SET_MODIS_DATA':
      return (payload.single) ? { ...s,
        'glad-modis': {...s['glad-modis'],single:payload.single}
      }:{ ...s,
        'glad-modis': {...s['glad-modis'],group:payload.group}
      }

    case 'SET_VIIRSMODIS_DATA':
      return (payload.single) ? { ...s,
        'glad-viirs+modis': {...s['glad-viirs+modis'],single:payload.single}
      }:{ ...s,
        'glad-viirs+modis': {...s['glad-viirs+modis'],group:payload.group}
      }

    case 'RESET_SINGLE_DATA':
    case 'INIT_SINGLE_DATA':
    return { ...s,
      "glad-viirs": {
        ...s["glad-viirs"],
        single: null
      },
      "glad-modis": {
        ...s["glad-modis"],
        single: null
      },
      "glad-viirs+modis": {
        ...s["glad-viirs+modis"],
        single: null
      },
    }

    default:
      return s;
  }
};
export default FireAlertReducer

