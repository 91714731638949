const inits = {
  loreal: !1
}

const LorealReducer = (s = inits, action) => {
  const {payload} = action
  switch (action.type) {
    case '_LOREAL':
      return { ...s, [payload[0]]: payload[1] }

    default:
      return s;
  }
};
export default LorealReducer
