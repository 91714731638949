const inits = {
  searchData: [],
  open: 0,
  filterid: 0,
  filterText: '',
  page: 1,
  showBg: false,
  min: true,
  percent: false,
  hasData: !1,
  sortData: [],
  sortcolumn: 'areaha',
  sortby: 'polyarea',
  sort: 'desc',
  alert: false,
  yr: '-1',
  astatus: 'all',
  daterange: `{}`,
  aSearchForm: true,
  aSearchLoader: false
}

const SearchReducer = (s = inits, action) => {
  const {payload} = action
  switch (action.type) {
    case '_SEARCH':
      return { ...s, [payload[0]]: payload[1] }

    case 'RESET_ADVANCED_SEARCH_STATE':
      return { ...s,
        sortData: !1,
        aSearchForm: 1,
        searchData: [],
        min: !1
      }

    default:
      return s;
  }
};
export default SearchReducer
