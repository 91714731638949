import moment from 'moment';

let gDt = new Date(),
// dpS = moment().subtract(365, "days").format('YYYY-MM-DD'),
dpS = `${moment().subtract(1, "year").format('YYYY')}-01-01`,
dpE = moment().format('YYYY-MM-DD'),
dSD = moment().subtract(30, "days").format('YYYY-MM-DD');

const inits = {
  sensor: 'GLAD + RADD',
  "glad": {
    group: null,
    single: null
  },
  "glad-radd": {
    group: null,
    single: null
  },
  "glad+radd": {
    group: null,
    single: null
  },
  startDate: dSD,
  endDate: dpE,
  unconfirmed: true,
  tab0:'recent',
  tab:'last30days',
  dpS,
  dpE,
  options: !1,
}

const ForestAlertReducer = (s = inits, action) => {
  const {payload} = action
  switch (action.type) {
    case '_FOREST_ALERT':
      return { ...s, [payload[0]]: payload[1] }

    case 'SET_FOREST_ALERT_STATE':
      return { ...s,
        startDate: payload[0],
        endDate: payload[1],
        unconfirmed: payload[2]
      }
    case '_FOREST_ALERT_CHECK_MAX':
      if(s.endDate > payload){
        return { ...s,
          endDate: payload,
          startDate: payload < s.startDate ? moment(payload).subtract(14, "days").format('YYYY-MM-DD') : s.startDate
        }
      }else{return s;}

    case '_FOREST_ALERT_ANS':
      return { ...s,
        startDate: s.dpS > payload[0] ? s.dpS : payload[0],
        endDate: payload[1],
        dpE: s.dpE < payload[1] ? payload[1] : s.dpE
      }

    case '_FOREST_ALERT_FROM_LINK':
      return { ...s,...payload}

    case 'SET_GLAD_DATA':
      return (payload.single) ? { ...s,
        'glad': {...s['glad'],single:payload.single}
      }:{ ...s,
        'glad': {...s['glad'],group:payload.group}
      }

    case '_GLAD_FROM_LINK':
      return { ...s,...payload}

    case 'SET_RADD_DATA':
      return (payload.single) ? { ...s,
        'glad-radd': {...s['glad-radd'],single:payload.single}
      }:{ ...s,
        'glad-radd': {...s['glad-radd'],group:payload.group}
      }

    case '_RADD_FROM_LINK':
      return { ...s,...payload}

    case 'SET_GLADRADD_DATA':
      return (payload.single) ? { ...s,
        'glad+radd': {...s['glad+radd'],single:payload.single}
      }:{ ...s,
        'glad+radd': {...s['glad+radd'],group:payload.group}
      }

    case 'RESET_SINGLE_DATA':
    case 'INIT_SINGLE_DATA':
    return { ...s,
      "glad": {
        ...s["glad"],
        single: null
      },
      "glad-radd": {
        ...s["glad-radd"],
        single: null
      },
      "glad+radd": {
        ...s["glad+radd"],
        single: null
      },
    }

    default:
      return s;
  }
};
export default ForestAlertReducer
