import moment from 'moment'

const inits = {
  show:!1,
  tab: 'forest',
  forestTab0: 'recent',
  fireTab0: 'recent',
  regionList: [],
  from: moment().subtract(30, 'days').format('YYYY-MM-DD'),
  to: moment().format('YYYY-MM-DD'),
  forestInterval: 'last30days',
  fireFrom: moment().format('YYYY-MM-DD'),
  fireTo: moment().format('YYYY-MM-DD'),
  fireInterval: 1,
  open: !1,
  active: {n:'',c:''},
  sensorForest: 'GLAD + RADD',
  sensorFire: 'VIIRS + MODIS',
  gladRaster: !1,
  raddRaster: !1,
  gladRaddRaster: !1,
  rIOP: !1,
  rIOPGroup: !1,
  rMills: !1,
  rMills25: !1,
  rMillsOl: !1,
  rMillsOl25: !1,
  rMillsCon: !1,
  rITP: !1,
  rITPGroup: !1,
  rDistricts: !1,
  rSubdistricts: !1,
  rVillages: !1,
  rPA: !1,
  rMining: !1,
  drIOP: !1,
  drIOPGroup: !1,
  drMills: !1,
  drMills25: !1,
  drMillsOl: !1,
  drMillsOl25: !1,
  drMillsCon: !1,
  drITP: !1,
  drITPGroup: !1,
  drDistricts: !1,
  drSubdistricts: !1,
  drVillages: !1,
  drPA: !1,
  drMining: !1,

  fIOP: !1,
  fIOPGroup: !1,
  fMills: !1,
  fMills25: !1,
  fMillsOl: !1,
  fMillsOl25: !1,
  fMillsCon: !1,
  fITP: !1,
  fITPGroup: !1,
  fDistricts: !1,
  fSubdistricts: !1,
  fVillages: !1,
  fPA: !1,
  fMining: !1,
  dFIOP: !1,
  dfIOPGroup: !1,
  dfMills: !1,
  dfMills25: !1,
  dfMillsOl: !1,
  dfMillsOl25: !1,
  dfMillsCon: !1,
  dfITP: !1,
  dfITPGroup: !1,
  dfDistricts: !1,
  dfSubdistricts: !1,
  dfVillages: !1,
  dfPA: !1,
  dfMining: !1,
  dashForestOption: !1,
  dashFireOption: !1,
  lorealOnly: !1,
}
//
// function setRegionList(d){
//   var i = 0, t = d.length, r = ['All'];
//   while(i<t){
//     r = [...r,d[i].region]
//     i++;
//   }
//   return r;
// }

const DashboardReducer = (s = inits, action) => {
  const {payload} = action
  switch (action.type) {
    case '_DASHBOARD':
      return { ...s, [payload[0]]: payload[1] }

    case 'SET_DASHBOARD_REGION_LIST':
      // return { ...s, regionList: setRegionList(payload) }
      return { ...s, regionList: payload }

    case 'SET_DASHBOARD_STATE':
      return { ...s,
        active: payload[0], open: !1, from: payload[1][0], to: payload[1][1],
        rIOP: !1,rIOPGroup: !1,rMills: !1,rMills25: !1,rMillsOl: !1,rMillsOl25: !1,rMillsCon: !1,rITP: !1,rITPGroup: !1,rDistricts: !1,rSubdistricts: !1,rVillages: !1,rPA: !1, rMining: !1,
        fIOP: !1,fIOPGroup: !1,fMills: !1,fMills25: !1,fMillsOl: !1,fMillsOl25: !1,fMillsCon: !1,fITP: !1,fITPGroup: !1,fDistricts: !1,fSubdistricts: !1,fVillages: !1,fPA: !1, fMining: !1,
      }

    case '_DASHBOARD_SET_DATA':
      return { ...s,
        [payload[0]]: payload[1],
        [`d${payload[0]}`]: payload[1]
      }

    default:
      return s;
  }
};
export default DashboardReducer
