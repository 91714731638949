const inits = {
  mapClicked: null,
  viewMap: null,
  sgs:null,
  cIDs:[],
  uids: [],
  single:null,
  goSearch:null,
  aSearch:null,
  areaha: 0,
  generateTimelapse: false,
  alertNotif: false,
}

const AppReducer = (s = inits, action) => {
  const {payload} = action
  switch (action.type) {
    case '_APP':
      return { ...s, [payload[0]]: payload[1] }

    case 'SET_START':
      return { ...s,
        goSearch: null,
        sgs: null,
      }

    case 'SET_SINGLE_CLICK':
      return { ...s,
        single: payload,
        sgs: 'single',
      }

    case 'SEARCH':
      const isArray = Array.isArray(payload);
      return { ...s,
        goSearch: isArray ? payload[0] : payload,
        generateTimelapse: isArray ? payload[1] : false,
        alertNotif: isArray ? payload[2] : false,
        sgs: 'group'
      }

    case 'INIT_ADVANCED_SEARCH':
      const t = {...inits,aSearch:1}
      return {...s,...t}

    case 'INIT':
      return {...s,...inits}


    default:
      return s;
  }
};
export default AppReducer
