const inits = {
  list: [],
  radius: 10000,
  sort: 'distance',
  selectedTraceCID: !1,
};

const TraceReducer = (s = inits, action) => {
  const { payload } = action;
  switch (action.type) {
    case '_TRACE':
      return { ...s, [payload[0]]: payload[1] }

    default:
      return s;
  }
};

export default TraceReducer;
