const inits = {
  popup: null,
  title: '',
  url: '',
  html: ''
}

const TabReducer = (s = inits, action) => {
  const {payload} = action
  switch (action.type) {
    case '_POPUP':
      return { ...s, [payload[0]]: payload[1] }

    case '_POPUP_RESET':
      return { ...s, ...inits }

    default:
      return s;
  }
};
export default TabReducer
