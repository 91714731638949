import "typeface-roboto"
import "typeface-roboto-condensed"
import './App.css';
import './styles/mobile.css';

import React, { lazy, Suspense } from 'react';
// import {useDispatch} from "react-redux";
import ReactDOM from 'react-dom'
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import { Provider } from 'react-redux'
import store from './store'

// import App from './App';
// import PageUserEdit from "./pages/user/PageUserEdit";
import AppLoader from "./components/loaders/AppLoader";
import PageUserNew from "./pages/user/PageUserNew";

const App = lazy(() => import('./App'));
const PageUserEdit = lazy(() => import('./pages/user/PageUserEdit'));

// import AppForMobileLayout from './mobile/App';

// import {is_mobile} from './helpers';

const renderLoader = () => <AppLoader/>;

const Main = () => {
  // const setApp = useDispatch();
  // if (is_mobile()) {
  //   setApp({type: '_STATIC', payload: ['isMobileLayout', 1]});
  // }
  // useEffect(() => {
  //   const checkViewport = () => {
  //     setIsMobile(is_mobile());
  //   }
  //   window.addEventListener('resize', checkViewport);
  //   return () => {
  //     window.removeEventListener('resize', checkViewport);
  //   }
  // }, []);

  return (
    <Router>
      <Routes>
        {
					/*
					<Route path="/embed/dashboard" element={<ApiDashboard/>}>
					*/
				}
        {
          /*
          is_mobile() ? (
            <Route path="/" element={<AppForMobileLayout/>}/>
          ) : (
            <Route path="/" element={<App/>}/>
          )
          */
        }
        <Route path="/" element={
          <Suspense fallback={renderLoader()}>
            <App/>
          </Suspense>
        }/>
        
        <Route path="/user/edit/:id" element={
          <Suspense fallback={renderLoader()}>
            <PageUserEdit/>
          </Suspense>
        }/>
        <Route path="/user/edit" element={
          <Suspense fallback={renderLoader()}>
            <PageUserNew/>
          </Suspense>
        }/>
      
      </Routes>
    </Router>
  );
}

const rootElement = document.getElementById('root')
ReactDOM.render(
  <Provider store={store}>
    <Main/>  
  </Provider>
  ,
  rootElement
)
