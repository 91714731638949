const inits = {
  welcomeScreen: false,
  welcomeText: {
    search: ['', ''],
    dashboard: ['', ''],
    themes: ['', ''],
    timelapse: ['', ''],
    stories: ['', ''],
  }
}

const WelcomeReducer = (s = inits, action) => {
  const {payload} = action
  switch (action.type) {
    case '_WELCOME':
      return { ...s, [payload[0]]: payload[1] }

    default:
      return s;
  }
};
export default WelcomeReducer
