const inits = {
  sliderMinYear: 2001,
  sliderMaxYear: 2022,
  rasterLayers: {
    landcover_lossyear: {
      play: false,
      playYear: 2022,
      yearRange: [2001, 2022],
      raster: null
    },
    landcover_expansionyeariop: {
      play: false,
      playYear: 2022,
      yearRange: [2001, 2022],
      raster: null
    },
    landcover_expansionyearsmallholder: {
      play: false,
      playYear: 2022,
      yearRange: [2001, 2022],
      raster: null
    },
    landcover_expansionyearitp: {
      play: false,
      playYear: 2022,
      yearRange: [2001, 2022],
      raster: null
    }
  }
};

const DynamicLayersReducer = (s = inits, action) => {
  const { payload } = action;
  switch (action.type) {
    case '_DYNAMIC_LAYER':
      return { ...s, [payload[0]]: payload[1] };

    case '_DYNAMIC_LAYER_SET_STATE':
      return { ...s, ...payload };

    case '_DYNAMIC_LAYER_SET_RASTER':
      return {
        ...s,
        rasterLayers: {
          ...s.rasterLayers,
          [payload.id]: {
            ...s.rasterLayers[payload.id],
            raster: payload.raster
          }
        }
      };
    case '_DYNAMIC_LAYER_SET_YEAR_RANGE':
      return {
        ...s,
        rasterLayers: {
          ...s.rasterLayers,
          [payload.id]: {
            ...s.rasterLayers[payload.id],
            yearRange: payload.yearRange
          }
        }
      };

    case '_DYNAMIC_LAYER_SET_PLAY':
      return {
        ...s,
        rasterLayers: {
          ...s.rasterLayers,
          [payload.id]: {
            ...s.rasterLayers[payload.id],
            play: payload.play
          }
        }
      };

    case '_DYNAMIC_LAYER_SET_PLAY_YEAR':
      return {
        ...s,
        rasterLayers: {
          ...s.rasterLayers,
          [payload.id]: {
            ...s.rasterLayers[payload.id],
            playYear: payload.playYear
          }
        }
      };

    default:
      return s;
  }
};

export default DynamicLayersReducer;