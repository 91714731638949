const inits = {
  mobileMenuState: null,
}

const mobileMenuReducer = (s = inits, action) => {
  const {payload} = action
  switch (action.type) {
    case '_MOBILE_MENU':
      return { ...s, [payload[0]]: payload[1] }

    default:
      return s;
  }
};
export default mobileMenuReducer
