import React from 'react'
import {useSelector} from 'react-redux'

const AppLoader = () => {

  const {appLoader, freezeLoader} = useSelector(s=>s.loader)

  return !appLoader && !freezeLoader ? null : (
    <div id="loading"><table className="width-100 height-100"><tbody><tr><td className="vertical-middle text-center">
      <div className="lds-ripple"><div></div><div></div></div>
      <p>Please wait. This may take a while...</p>
    </td></tr></tbody></table></div>
  )
}

export default AppLoader
