const inits = {
  is_init: true
}

const InitReducer = (s = inits, action) => {
  const {payload} = action
  switch (action.type) {
    case '_INIT':
      return { ...s, [payload[0]]: payload[1] }

    default:
      return s;
  }
};
export default InitReducer
