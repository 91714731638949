const inits = {
  activeLayers: [],
  showLayers: [],
  linkLayers: [],
  lastLayers: [],
}

const ALayersReducer = (s = inits, action) => {
  const {payload} = action
  switch (action.type) {
    case '_A_LAYERS':
      return { ...s, [payload[0]]: payload[1] }

    case 'SET_A_LAYERS':
      return { ...s,
        activeLayers: payload.init.default.concat(payload.init.oilpalm),
        showLayers: payload.init.default.concat(payload.init.oilpalm)
      }

    case '_A_LAYERS_FILTER':
      return { ...s,
        showLayers: [...s.activeLayers.filter( ( el ) => !payload.includes( el ) )]
      }

    case 'REMOVE_THIS_LAYER':
      return { ...s,
        activeLayers: [...s.activeLayers.filter(t => t !== payload)],
        showLayers: [...s.activeLayers.filter(t => t !== payload)]
      }

    case 'SET_START_LAYERS':
      return { ...s,
        activeLayers: [].concat(payload),
        showLayers: [].concat(payload)
      }

    case 'SET_START_LAYERS_FROM_LINK':
      return { ...s,
        activeLayers: [].concat(payload),
        showLayers: [].concat(payload)
      }

    case 'REMOVE_SEARCHED_LAYER':
      return { ...s,
        activeLayers: s.defaultLayers.default.concat(payload),
        showLayers: s.defaultLayers.default.concat(payload)
      }

    case 'SET_RISK_LAYERS':
      const a = ['landCover','landcover_plantation','landcover_mining','landcover_transmigration']
      return { ...s,
        showLayers: [...s.activeLayers.filter(t => a.indexOf(t) === -1 )]
      }



    default:
      return s;
  }
};
export default ALayersReducer
