import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import config from '../../config';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

function SubmitForm({
  user,
  userData,
  setLoader,
  setMessage,
}) {
  setLoader(true);
  setMessage([]);
  let error = [];
  if(userData.name_first.trim() === ''){
    error = error.concat('<div class="alert alert-danger"><b>First name</b> is required!</div>');
  }
  
  if(userData.email.trim() === ''){
    error = error.concat('<div class="alert alert-danger"><b>Email</b> is required!</div>');
  }

  if(userData.password.trim() === ''){
    error = error.concat('<div class="alert alert-danger"><b>Password</b> is required!</div>');
  }

  if (error.length) {
    setMessage(error);
    setLoader(false);
    return;
  }
  
  if (user.role === 1) {
    axios.post('/api/save-new-user', {
      name_first: userData.name_first,
      name_last: userData.name_last,
      institution: userData.institution,
      country: userData.country,
      email: userData.email,
      password: window.pageData.sha256(userData.password),
      role: userData.role,
      status: userData.status,
      userGroups: userData.userGroups,
      userGroupParents: userData.userGroupParents,
    }).then(function(r){
    if (r.data.status === true) {
        setMessage([
          '<div class="alert alert-success"><i class="fa fa-check"></i> Data saved!</div>',
        ]);
        setTimeout(() => {
          window.location.href = `/user/edit/${r.data.data.id}`;
        }, 1500);
      }else{
        setMessage([
          `<div class="alert alert-danger"><b>${r.data.message}</b></div>`,
        ]);
      }
      setLoader(false);
    }).catch(function(err){
      console.log(err);
    });
  }
}

const PageUserNew = () => {
  const setApp = useDispatch();
  const {user} = useSelector(s => s.auth);
  const [userData, setUserData] = useState(null);
  const [countries, setCountries] = useState([]); 
  const [userGroups, setUserGroups] = useState([]);
  const [userGroupParents, setUserGroupParents] = useState([]);
  const [loader, setLoader] = useState(false);
  const [message, setMessage] = useState([]);
  const [currentEmail, setCurrentEmail] = useState('');
  useEffect(()=>{
    axios.get(`${config.apiLink}auth/`)
    .then((r)=>{
      setApp({ type: '_AUTH_LOGGED_IN', payload: r.data });
      // window.reactLoaded();

      axios.get('/api/user-new')
        .then(response => {
          if (response.data.status) {
            setUserData(response.data.udata);
            setCountries(response.data.countries);
            setUserGroups(response.data.usergroups);
            setUserGroupParents(response.data.usergroup_parents);
          } else {
            window.location.reload();
          }
        })
    })
    if (window.location.hash === '#id') {
      setApp({ type: '_LANG', payload: ['lang', 1] });
    }
  }, []);
  
  const hasAccess = user?.role === 1 || false;

  return !hasAccess || !userData ? <div><img alt="" width="40" height="40" src="/assets/images/ripple.gif"/> <i>Loading . . .</i></div> : (
    <form id="form" className="form-horizontal backend"
      onSubmit={(event) => {
        event.preventDefault();
        SubmitForm({
          user,
          userData,
          currentEmail,
          setCurrentEmail,
          setLoader,
          setMessage,
        });
      }}
    >
      <style>
        {
          `
          .assigned-data > div {
            padding: 5px 15px 15px;
            background: #f5f5f5;
            border: 1px solid #ddd;
          }
          `
        }
      </style>
      <div className="box-body">
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label className="col-sm-2 control-label">First Name</label>
              <div className="col-sm-10">
                <input onChange={(event) => {
                  setUserData({...userData, name_first: event.target.value});
                }} type="text" className="form-control" id="name_first" placeholder="First name" value={userData.name_first}/>
              </div>
            </div>
            <div className="form-group">
              <label className="col-sm-2 control-label">Last Name</label>
              <div className="col-sm-10">
                <input onChange={(event) => {
                  setUserData({...userData, name_last: event.target.value});
                }} type="text" className="form-control" id="name_last" placeholder="Last name" value={userData.name_last}/>
              </div>
            </div>
            <div className="form-group">
              <label className="col-sm-2 control-label">Affiliation</label>
              <div className="col-sm-10">
                <input onChange={(event) => {
                  setUserData({...userData, institution: event.target.value});
                }} type="text" className="form-control" id="institution" placeholder="Affiliation" value={userData.institution}/>
              </div>
            </div>
            <div className="form-group">
              <label className="col-sm-2 control-label">Country</label>
              <div className="col-sm-10">
                <select 
                  id="country" 
                  className="form-control" 
                  name="country"
                  value={userData.country}
                  onChange={(event) => {
                    setUserData({...userData, country: event.target.value});
                  }}
                >
                  <option value="">Choose..</option>
                  {
                    countries.map(country => {
                      return (
                        <option key={country} value={country}>{country}</option>    
                      )
                    })
                  }
                </select>
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="email" className="col-sm-2 control-label">Email</label>

              <div className="col-sm-10">
                <input onChange={(event) => {
                    if (user.role === 1) {
                      setUserData({...userData, email: event.target.value});
                    }
                  }} 
                  type="email" 
                  className="form-control" 
                  id="email" 
                  placeholder="Email" 
                  value={userData.email}
                  disabled={user.role === 1 ? false : true}
                  />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="password" className="col-sm-2 control-label">Password</label>

              <div className="col-sm-10">
                <input onChange={(event) => {
                  setUserData({...userData, password: event.target.value});
                }} type="password" className="form-control" id="password" placeholder="Password"/>
              </div>
            </div>
            {
              user.role === 1 && (
                <>
                  <div className="form-group">
                    <label className="col-sm-2 control-label">Role</label>
                    <div className="col-sm-10">
                      <select 
                        id="role" 
                        className="form-control" 
                        name="role"
                        value={userData.role}
                        onChange={(event) => {
                          setUserData({...userData, role: parseInt(event.target.value)});
                        }}
                      >
                        <option value="" disabled>Choose..</option>
                        <option value="5">Premium user</option>
                        <option value="3">Public user</option>
                        <option value="4">Photo Author</option>
                        <option value="2">News & Photo Author</option>
                        <option value="1">Admin</option>
                      </select>
                    </div>
                  </div>

                  {
                    parseInt(userData.role) === 5 && (
                      <div className="form-group">
                        <div className='col-sm-2'></div>
                        <div className="assigned-data col-sm-10">
                          <div className="">
                            <div className="">
                              <p><label className="control-label">Assigned Group Parents</label></p>
                              <Autocomplete
                                multiple
                                id="assigned-group_parent"
                                options={userGroupParents}
                                getOptionLabel={(option) => option.usergroup_parent_name}
                                value={userData.userGroupParents}
                                onChange={(event, newValue) => {
                                  const relatedGroups = userGroups.filter(group => {
                                    return newValue.some((prop) => {
                                      return prop.usergroup_parent_id === group.usergroup_parent_id;
                                    });
                                  });
                                  setUserData({
                                    ...userData, 
                                    userGroupParents: newValue,
                                    userGroups: [...new Set(userData.userGroups.concat(relatedGroups))],
                                  });
                                }}
                                filterSelectedOptions
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Group Parent"
                                    placeholder="Type here..."
                                    size="small"
                                    sx={{ background: '#fff' }}
                                  />
                                )}
                              />
                            </div>
                            <div style={{ marginTop: 10 }}>
                              <p>
                                <label className="control-label">Assigned Groups</label>
                              </p>
                              <Autocomplete
                                multiple
                                id="assigned-group"
                                options={userGroups}
                                getOptionLabel={(option) => option.usergroup_name}
                                value={userData.userGroups}
                                onChange={(event, newValue) => {
                                  const relatedGroups = userGroups.filter(group => {
                                    return userData.userGroupParents.some((prop) => {
                                      return prop.usergroup_parent_id === group.usergroup_parent_id;
                                    });
                                  });
                                  const value = [...new Set(newValue.concat(relatedGroups))];
                                  setUserData({...userData, userGroups: value});
                                }}
                                filterSelectedOptions
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Groups"
                                    placeholder="Type here..."
                                    size="small"
                                    sx={{ background: '#fff' }}
                                  />
                                )}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  }
                  {
                    user.role === 1 && 
                    <div className="form-group">
                      <label className="col-sm-2 control-label">Status</label>
                      <div className="col-sm-10">
                        <select 
                          id="status" 
                          className="form-control" 
                          name="status"
                          value={userData.status}
                          onChange={(event) => {
                            setUserData({...userData, status: event.target.value});
                          }}
                        >
                          <option value="1">Active</option>
                          <option value="0">Inactive</option>
                          <option value="2" disabled>Unverified</option>
                        </select>
                      </div>
                    </div>
                  }
                </>
              )
            }
          </div>
        </div>
      </div>

      <div className="box-footer">
        <div className="row">
          <div className="col-sm-2"></div>
          <div className="col-sm-10">
            <div id="alert">
              {
                message.length > 0 &&
                <>
                  {
                    message.map(message => {
                      return <div key={message} dangerouslySetInnerHTML={{__html: message}} />
                    })
                  }
                </>
              }
            </div>
            {
              loader ? (
                <img id="btn-loading" style={{ width: 40, height: 'auto' }} src="/assets/images/ripple.gif"/> 
              ) : (
                <button type="submit" className="btn btn-primary">Save new User</button>
              )
            }
          </div>
        </div>
      </div>
    </form>
  )
}

export default PageUserNew;