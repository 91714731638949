const inits = {
  sbTab: !1,
  activeTab: 'glad+radd',
  stickyTab: [],
  defaultTab: 'glad+radd',
  activeAlertSensor: 'glad+radd',
}

const TabReducer = (s = inits, action) => {
  const {payload} = action
  switch (action.type) {
    case '_TAB':
      const s_updated = {...s};
      if (payload[1]) {
        if (payload[0] === 'activeTab' && payload[1].indexOf('glad') === 0) {
          s_updated.activeAlertSensor = payload[1];
        }
      }
      return { ...s_updated, [payload[0]]: payload[1] }

    case '_TAB_INIT':
      return s.sbTab ? { ...s,
        activeTab: payload
      } : s

    case 'SET_INIT_TAB':
      return { ...s,
        activeTab: s.defaultTab
      }

    case 'SET_DEFAULT_TAB':
      return s.activeTab === 'search' || s.activeTab === 'map' ? { ...s,
        activeTab: s.defaultTab
      } : s

    case '_TAB_CLOSE_HOWTO':
      return s.sbTab === 'howto' ? { ...s,
        sbTab: !1
      } : s
      
    default:
      return s;
  }
};
export default TabReducer
