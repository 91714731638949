const startYear = 2001
const endYear = new Date().getFullYear() - 1
const rLang = {
  "AppName": [
    "ANALYZE LAND COVER CHANGES", "ANALISA PERUBAHAN TUTUPAN LAHAN"
  ],
  "TOOLS": [
    "TOOLS", "ALAT"
  ],
  "ADD_MAP": [
    "ADD MAP", "TAMBAH PETA"
  ],
  "BASE_MAP": [
    "BASE MAP", "PETA DASAR"
  ],
  "W1": [
    "An independent geo-platform that reveals the true impact of oil palm and pulpwood industrial plantations on forests in Equatorial Asia by showing several decades of land cover change with time-lapse animations.",
    "Sebuah wahana kebumian independen yang mengungkap dampak nyata perkebunan kelapa sawit dan pulp industrial terhadap hutan di Khatulistiwa Asia melalui perubahan tutupan lahan selama beberapa dekade dengan animasi periodik."
  ],
  "W2": [
    "Track deforestation weekly to rapidly detect who might be responsible as it happens",
    "Melacak deforestasi setiap minggu untuk mendeteksi dengan cepat siapa yang mungkin bertanggung jawab saat itu terjadi"
  ],
  "W3": [
    "Track the annual expansion of plantations",
    "Melacak ekspansi tahunan perkebunan"
  ],
  "W4": [
    "Monitor companies' compliance with zero-deforestation targets",
    "Memonitor kepatuhan perusahaan terhadap target nol-deforestasi"
  ],
  "W5": [
    "Monitor performance of protected areas",
    "Memonitor performa kawasan konservasi"
  ],
  "W6": [
    "Monitor effectiveness of policies to reduce deforestation and to control the expansion of plantations",
    "Memonitor efektivitas kebijakan pengurangan deforestasi dan mengendalikan ekspansi perkebunan"
  ],
  "W7": [
    "Monitor impact of road developments on forests",
    "Memonitor dampak pembangunan jalan terhadap hutan"
  ],
  "AnalyzeIn": [
    "Analyze land cover changes in:", "Analisis perubahan tutupan lahan terhadap:"
  ],
  "StartTool": [
    "Analyze", "Analisa"
  ],
  "SearchedLayer": [
    "Searched layer","Layer hasil pencarian"
  ],
  "ClickedLayer": [
    "Clicked layer","Layer hasil klik"
  ],
  "Name": [
    "Name", "Nama"
  ],
  "Group": [
    "Group", "Grup"
  ],
  "Roads": [
    "Roads", "Jalan"
  ],
  "Country": [
    "Country", "Negara"
  ],
  "Countries": [
    "Countries", "Negara"
  ],
  "Province": [
    "Province", "Provinsi"
  ],
  "Provinces": [
    "Provinces", "Provinsi"
  ],
  "District": [
    "District", "Kabupaten"
  ],
  "Districts": [
    "Districts", "Kabupaten"
  ],
  "Subdistrict": [
    "Subdistrict", "Kecamatan"
  ],
  "Subdistricts": [
    "Subdistricts", "Kecamatan"
  ],
  "Village": [
    "Village", "Desa"
  ],
  "Villages": [
    "Villages", "Desa"
  ],
  "National": [
    "National", "Nasional"
  ],
  "ForestZone": [
    "Forest Zone", "Kawasan Hutan"
  ],
  "PapuaProvince": [
    "Papua Province", "Provinsi Papua"
  ],
  "WestPapuaProvince": [
    "West Papua Province", "Provinsi Papua Barat"
  ],
  "Concessions": [
    "Concessions", "Konsesi"
  ],
  "toilpalm": [
    "Oilpalm concessions",
    "Konsesi kelapa sawit"
  ],
  "tpulpwood": [
    "Pulpwood concessions",
    "Konsesi kayu pulp"
  ],
  "tlogging": [
    "Logging concessions",
    "Konsesi penebangan"
  ],
  "tmining": [
    "Mining concessions",
    "Konsesi tambang"
  ],
  "tconcessionother": [
    "Other concessions",
    "Konsesi lainnya"
  ],
  "tmill": [
    "Near palm oil mills",
    "Dekat pabrik kelapa sawit"
  ],
  "tprotected_area": [
    "Protected areas",
    "Kawasan konservasi"
  ],
  "tprotected_area_cl": [
    "Conservation landscapes",
    "Landskap konservasi"
  ],
  "tprotected_area_er": [
    "Ecosystem restorations",
    "Restorasi ekosistem"
  ],
  "tprotected_area_hr": [
    "Hydrological reserves",
    "Cagar alam hidrologis"
  ],
  "tsfa": [
    "Indigenous forest",
    "Hutan adat"
  ],
  "tsfd": [
    "Village forest",
    "Hutan desa"
  ],
  "tsfk": [
    "Community forest",
    "Hutan kemasyarakatan"
  ],
  "tsft": [
    "Community Plantation Forest",
    "Hutan tanaman rakyat"
  ],
  "tsfwiladat": [
    "Indigenous Territory",
    "Wilayah adat"
  ],
  "troad": [
    "Near roads",
    "Sekitar jalan"
  ],
  "tcountry": [
    "Country boundary",
    "Batas negara"
  ],
  "tprovince": [
    "Province boundary",
    "Batas provinsi"
  ],
  "tsubdistrict": [
    "Subdistrict boundary",
    "Batas kecamatan"
  ],
  "tvillage": [
    "Village boundary",
    "Batas desa"
  ],
  "tdistrict": [
    "District boundary",
    "Batas kabupaten"
  ],
  "tluzN": [
    "National",
    "Nasional"
  ],
  "tluzF": [
    "Forest zone",
    "Kawasan hutan"
  ],
  "tluzP": [
    "Papua province",
    "Provinsi Papua"
  ],
  "tluzPB": [
    "West Papua province",
    "Provinsi Papua Barat"
  ],
  "tluzMora": [
    "Moratorium",
    "Moratorium"
  ],
  "taoi": [
    "Area of Interest",
    "Wilayah perhatian anda"
  ],
  "taoiDRAW": [
    "Area of Interest",
    "Wilayah perhatian anda"
  ],
  "taoiZIP": [
    "Area of Interest",
    "Wilayah perhatian anda"
  ],
  "Oilpalm mills": [
    "Oilpalm mills", "Pabrik Kelapa Sawit"
  ],
  "Mills": [
    "Mills", "Pabrik"
  ],
  "NearMills": [
    "Near Mills", "Dekat Pabrik"
  ],
  "ProtectedArea": [
    "Protected Area", "Kawasan Konservasi"
  ],
  "AdministrativeBoundary": [
    "Administrative Boundary", "Batas Administratif"
  ],
  "Administrativeboundaries": [
    "Administrative boundaries", "Batas administratif"
  ],
  "Landuse": [
    "Landuse", "Pemanfaatan lahan"
  ],
  "Infrastructures" : [
    "Infrastructures", "Infrastruktur"
  ],
  "Alerts":[
    "Alerts", "Tanda Peringatan"
  ],
  "Landcovermaps":[
    "Landcover maps", "Peta tutupan lahan"
  ],
  "NearRoads": [
    "Near Roads", "Sekitar jalan"
  ],
  "LandUseZone": [
    "Land Use Zone", "Area Pemanfaatan Lahan"
  ],
  "LandUseZones": [
    "Land Use Zones", "Area Pemanfaatan Lahan"
  ],
  "IndigenousTerritories": [
    "Indigenous Territories", "Wilayah Adat"
  ],
  "AreaofInterest": [
    "Area of Interest", "Wilayah Perhatian Anda"
  ],
  "Mapsandlegend": [
    "Maps and legend", "Peta dan legenda"
  ],
  "Advancedsearch": [
    "Advanced search", "Cari lebih detil"
  ],
  "TWgroup": [
    "Parent companies/groups", "Perusahaan induk/grup"
  ],
  "Oilpalm": [
    "Oilpalm", "Kelapa Sawit"
  ],
  "Oilpalm groups": [
    "Oilpalm groups", "Grup Kelapa Sawit"
  ],
  "Oilpalm concessions": [
    "Oilpalm concessions", "Konsesi Kelapa Sawit"
  ],
  "Pulpwood": [
    "Pulpwood", "Kayu Pulp"
  ],
  "Pulpwood groups": [
    "Pulpwood groups", "Grup Kayu Pulp"
  ],
  "Pulpwood concessions": [
    "Pulpwood concessions", "Konsesi Kayu Pulp"
  ],
  "Smallholder": [
    "Smallholder", "Petani"
  ],
  "Logging": [
    "Logging", "Penebangan"
  ],
  "Mining": [
    "Mining", "Penambangan"
  ],
  "Transmigration": [
    "Transmigration", "Transmigrasi"
  ],
  "ToolConTitle": [
    "ANALYZE LAND COVER CHANGES<br>IN CONCESSIONS","ANALISIS PERUBAHAN TUTUPAN LAHAN DALAM KONSESI"
  ],
  "ToolConDesc": [
    "A concession is an area of land allocated by the government to a company to develop an industrial scale oil palm or pulpwood plantation or for logging in a natural forest, or for extracting minerals.",
    "Konsesi adalah sebuah kawasan lahan yang dialokasikan oleh pemerintah kepada perusahaan untuk membangun perkebunan kelapa sawit atau pulp skala industrial atau menebang hutan alam atau ekstraksi mineral."
  ],
  "ToolIOPTitle": [
    "ANALYZE LAND COVER CHANGES<br>IN OILPALM CONCESSIONS","ANALISIS PERUBAHAN TUTUPAN LAHAN DALAM KONSESI KELAPA SAWIT"
  ],
  "ToolIOPDesc": [
    "Visualize the reduction of old-growth forests and the expansion of industrial oil palm plantations annually over two decades in concessions. Verify ownership. Find out which plantations caused deforestation by clearing forests while others avoided this by using non-forest lands. Rank concessions from largest to smallest forest destroyer.",
    "Visualisasi penyusutan hutan primer dan ekspansi tahunan perkebunan kelapa sawit industrial selama dua dekade dalam konsesi. Verifikasi kepemilikan. Temukan perkebunan penyebab deforestasi dengan menebangi hutan dan perkebunan yang menghindari deforestasi dengan memanfaatkan lahan nonhutan. Peringkat konsesi perusak hutan terbesar hingga terkecil."
  ],
  "ToolMillTitle": [
    "ANALYZE LAND COVER CHANGES NEAR MILLS","ANALISIS PERUBAHAN TUTUPAN LAHAN DEKAT PABRIK"
  ],
  "ToolMillDesc": [
    "Visualize the loss of old-growth forests and the development of plantations near mills (within 10 km buffer). Verify ownership of mills. Rank mills in order of decreasing forest loss, company-driven deforestation, planted area or remaining forest",
    "Visualisasi kehilangan hutan primer dan pembangunan perkebunan di dekat pabrik (dalam 10 km area penyangga). Verifikasi kepemilikan pabrik. Peringkat pabrik sesuai dengan urutan penurunan kehilangan hutan, deforestasi akibat perusahaan, area tanam atau hutan tersisa"
  ],
  "ToolPATitle": [
    "ANALYZE LAND COVER CHANGES USE IN PROTECTED AREA","ANALISIS PERUBAHAN PEMANFATAAN TUTUPAN LAHAN DI DALAM KAWASAN KONSERVASI"
  ],
  "ToolPADesc": [
    "Visualize year-on-year threats to protected areas, like illegal plantations and logging road developments. Rank protected areas from most to least affected by these threats",
    "Visualisasi ancaman terhadap kawasan konservasi dari tahun ke tahun, antara lain perkebunan ilegal dan pembangunan jalan penebangan. Peringkat kawasan konservasi dari paling terpengaruh ke paling tidak terpengaruh akibat ancaman tersebut"
  ],
  "ToolAdminTitle": [
    "ANALYZE LAND COVER CHANGES<br/>IN ADMINISTRATIVE BOUNDARY","ANALISIS PERUBAHAN TUTUPAN LAHAN DI DALAM BATAS ADMINISTRATIF"
  ],
  "ToolAdminDesc": [
    "Visualize forest loss and plantation dynamics yearly in provinces and districts to understand drivers, evaluate the effectiveness of environmental policies, and develop landscape solutions to tropical deforestation.",
    "Visualisasi kehilangan hutan dan dinamika tahunan di provinsi dan kabupaten untuk memahami penyebab, mengevaluasi efektivitas kebijakan lingkungan hidup, dan mengembangkan solusi bentang alam bagi deforestasi tropis."
  ],
  "ToolCTitle": [
    "ANALYZE LAND COVER CHANGES<br/>IN COUNTRY BOUNDARY","ANALISIS PERUBAHAN TUTUPAN LAHAN DI DALAM BATAS NEGARA"
  ],
  "ToolPTitle": [
    "ANALYZE LAND COVER CHANGES<br/>IN PROVINCE BOUNDARY","ANALISIS PERUBAHAN TUTUPAN LAHAN DI DALAM BATAS PROVINSI"
  ],
  "ToolDTitle": [
    "ANALYZE LAND COVER CHANGES<br/>IN DISTRICT BOUNDARY","ANALISIS PERUBAHAN TUTUPAN LAHAN DI DALAM BATAS KABUPATEN"
  ],
  "ToolRTitle": [
    "ANALYZE LAND COVER CHANGES NEAR ROAD","ANALISIS PERUBAHAN TUTUPAN LAHAN SEKITAR JALAN"
  ],
  "ToolRDesc": [
    "Visualize forest loss and the development of industrial plantations near public roads (within 1km buffer) to investigate the impacts of road development, including the Trans-Papua highway.",
    "Visualisasi kehilangan hutan dan pengembangan perkebunan industrial di sekitar jalan umum (dalam 1 km penyangga) untuk menyelidiki dampak pembangunan jalan, termasuk jalan Trans-Papua."
  ],
  "ToolLUZTitle": [
    "ANALYZE LAND USE CHANGES IN LAND USE ZONE","ANALISIS PERUBAHAN TUTUPAN LAHAN DI AREA PEMANFAATAN LAHAN"
  ],
  "ToolLUZDesc": [
    "",
    ""
  ],
  "ToolLUZNTitle": [
    "ANALYZE LAND USE CHANGES<br>IN LAND USE ZONE (NATIONAL)",
    "ANALISIS PERUBAHAN TUTUPAN LAHAN DI AREA PEMANFAATAN LAHAN (NASIONAL)"
  ],
  "ToolLUZFTitle": [
    "ANALYZE LAND USE CHANGES<br>IN LAND USE ZONE (FOREST ZONE)",
    "ANALISIS PERUBAHAN TUTUPAN LAHAN DI AREA PEMANFAATAN LAHAN (KAWASAN HUTAN)"
  ],
  "ToolLUZPTitle": [
    "ANALYZE LAND USE CHANGES<br>IN LAND USE ZONE (PAPUA PROVINCE)",
    "ANALISIS PERUBAHAN TUTUPAN LAHAN DI AREA PEMANFAATAN LAHAN (PROVINSI PAPUA)"
  ],
  "ToolLUZPBTitle": [
    "ANALYZE LAND USE CHANGES<br>IN LAND USE ZONE (WEST PAPUA PROVINCE)",
    "ANALISIS PERUBAHAN TUTUPAN LAHAN DI AREA PEMANFAATAN LAHAN (PROVINSI PAPUA BARAT)"
  ],
  "ToolLUZMoraTitle": [
    "ANALYZE LAND USE CHANGES<br>IN LAND USE ZONE (MORATORIUM)",
    "ANALISIS PERUBAHAN TUTUPAN LAHAN DI AREA PEMANFAATAN LAHAN (MORATORIUM)"
  ],
  "ToolAoiTitle": [
    "ANALYZE LAND COVER CHANGES<br>IN YOUR AREA OF INTEREST",
    "ANALISIS PERUBAHAN TUTUPAN LAHAN<br>DI DALAM WILAYAH PERHATIAN ANDA"
  ],
  "ToolAoiDesc": [
    "Visualize forest loss and the development of industrial plantations in your area of interest (AoI) that you submitted (zipped shapefile) or by deliniating on the map",
    "Visualisasi kehilangan hutan dan pembangunan perkebunan industrial di dalam wilayah perhatian Anda (AoI) melalui peta yang telah Anda masukkan (format file zip) atau dengan menggambar pada peta"
  ],
  "TaoiZIP": [
    "Submit zipped shapefile","Kirim shapefile (zipped)"
  ],
  "TaoiDRAW": [
    "Draw AoI","Menggambar AoI"
  ],
  "TabGladHead": [
    "DEFORESTATION & FIRE ALERTS",
    "DEFORESTASI & PERINGATAN"
  ],
  "TabStatHead": [
    "SUMMARY STATISTICS",
    "Ringkasan Statistik"
  ],
  "Tab73Head": [
    "FIVE DECADES OF CHANGE",
    "Perubahan Lima Dekade"
  ],
  "TabTsHead": [
    "ANNUAL TRENDS",
    "Tren tahunan"
  ],
  "TabRiskHead": [
    "Deforestation risk",
    "Resiko kehilangan hutan"
  ],
  "sbStat1": [
    "Remaining forest in "+endYear+"","Hutan Tersisa "+endYear+""
  ],
  "sbStat2": [
    "Forest loss since "+startYear+"","Kehilangan hutan sejak "+startYear+""
  ],
  "sbStat3": [
    "Forest cleared and converted to plantations in the same year","Penebangan dan konversi hutan menjadi perkebunan di tahun yang sama"
  ],
  "sbStat4": [
    "Non-forest converted to plantations since "+startYear+"","Konversi lahan bukan hutan menjadi perkebunan sejak "+startYear+""
  ],
  "sbStat5": [
    "Existing industrial plantations in "+endYear+"","Perkebunan industrial pada "+endYear+""
  ],
  "sbStat6": [
    "Expansion of industrial plantations since "+startYear+"","Ekspansi perkebunan industrial sejak "+startYear+""
  ],
  "sbStatBa": [
    "Burned area in ","Luas terbakar "
  ],
  "sb733": [
    "Forest lost since 1973","Kehilangan hutan sejak 1973"
  ],
  "sb734": [
    "Forest converted to industrial plantation since 1973","Konversi hutan menjadi perkebunan industrial sejak 1973"
  ],
  "sbc1":["Land cover in","Tutupan lahan pada"],
  "sbc2":["Forest lost in","Kehilangan hutan pada"],
  "sbc3":["Forest on mineral soils lost in","Kehilangan hutan tanah mineral pada"],
  "sbc4":["Mangrove forest lost in","Kehilangan hutan mangrove pada"],
  "sbc5":["Peat-swamp forest lost in","Kehilangan hutan rawa gambut pada"],
  "sbc6":["Expansion of industrial oil-palm plantation and associated company-driven deforestation in","Ekspansi perkebunan kelapa sawit dan deforestasi akibat perusahaan terkait pada"],
  "sbc13":["Expansion of smallholder oil-palm plantation and associated smallholder deforestation in","Ekspansi perkebunan kelapa sawit dan deforestasi akibat petani kecil terkait pada"],
  "sbc7":["Expansion of pulpwood plantation and associated company-driven deforestation in","Ekspansi perkebunan kayu pulp dan deforestasi akibat perusahaan terkait pada"],
  "sbc8":["Expansion of mining and associated company-driven deforestation in","Ekspansi pertambangan dan deforestasi akibat perusahaan terkait pada"],
  "sbc9":["Expansion of main roads in","Ekspansi jalan umum pada"],
  "sbc10":["Expansion of other roads in","Ekspansi jalan lain pada"],
  "sbc11":["Expansion of plantation roads in","Ekspansi jalan perkebunan pada"],
  "sbc12":["Expansion of logging roads in","Ekspansi jalan tebangan pada"],
  "About_Map": [
    "About Map","Tentang Peta"
  ],
  "How_to": [
    "How to","Petunjuk"
  ],
  "Feedback": [
    "Feedback","Saran"
  ],
  "Share_this": [
    "Share this","Bagikan"
  ],
  "Terms_of_use": [
    "Terms of use","Aturan pengguna"
  ],
  "dashGladIOP": [
    "Concession with highest Forest loss alert","Konsesi dengan Peringatan kehilangan hutan tertinggi"
  ],
  "dashGladIOPGroup": [
    "Group with highest Forest loss alert","Grup dengan Peringatan kehilangan hutan tertinggi"
  ],
  "dashGladITP": [
    "Concession with highest Forest loss alert","Konsesi dengan Peringatan kehilangan hutan tertinggi"
  ],
  "dashGladITPGroup": [
    "Group with highest Forest loss alert","Grup dengan Peringatan kehilangan hutan tertinggi"
  ],
  "dashGladIOPMill": [
    "Oilpalm mill with highest Forest loss alert","Pabrik kelapa sawit dengan Peringatan kehilangan hutan tertinggi"
  ],
  "dashGladDistrict": [
    "District with highest Forest loss alert","Kabupaten dengan Peringatan kehilangan hutan tertinggi"
  ],
  "dashGladPA": [
    "Protected area with highest Forest loss alert","Kawasan konservasi dengan Peringatan kehilangan hutan tertinggi"
  ],
  "dashFireIOP": [
    "Concession with highest Fire hotspot","Konsesi dengan Titik panas api tertinggi"
  ],
  "dashFireIOPGroup": [
    "Group with highest Fire hotspot","Grup dengan Titik panas api tertinggi"
  ],
  "dashFireITP": [
    "Concession with highest Fire hotspot","Konsesi dengan Titik panas api tertinggi"
  ],
  "dashFireITPGroup": [
    "Group with highest Fire hotspot","Grup dengan Titik panas api tertinggi"
  ],
  "dashFireIOPMill": [
    "Oilpalm mill with highest Fire hotspot","Pabrik kelapa sawit dengan Titik panas api tertinggi"
  ],
  "dashFireDistrict": [
    "District with highest Fire hotspot","Kabupaten dengan Titik panas api tertinggi"
  ],
  "dashFirePA": [
    "Protected area with highest Fire hotspot","Kawasan konservasi dengan Titik panas api tertinggi"
  ],
  "today": [
    "Today",
    "Hari ini"
  ],
  "last24": [
    "Last 2 days",
    "2 hari terakhir"
  ],
  "last2days": [
    "Last 2 days",
    "2 hari terakhir"
  ],
  "last7": [
    "Last 7 days",
    "7 hari terakhir"
  ],
  "last14days": [
    "Last 14 days",
    "14 hari terakhir"
  ],
  "last30days": [
    "Last 30 days",
    "30 hari terakhir"
  ],
  "last60days": [
    "Last 60 days",
    "60 hari terakhir"
  ],
  "last180days": [
    "Last 180 days",
    "180 hari terakhir"
  ],
  "last360days": [
    "Last 360 days",
    "360 hari terakhir"
  ],
};
export default rLang
