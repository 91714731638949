const yearArray = (i,t,r) => {
  let d=[]
  while(i<=t){
    d.push(r ? i : 0)
    i++
  }
  return d
}

const startYear = 2001
const endYear = window.dataYear
// const endYear = new Date().getFullYear() - 1

const inits = {
  startYear,
  endYear,
  ycArray: yearArray(startYear,endYear,false),
  cCats: yearArray(startYear,endYear,true),
  y: window.dataYear,
  play: false,
  sat: false,
  sliderStartYear: window.minDataYear,
  sliderEndYear: window.dataYear,
  tsRasterDF: !1,
  tsRasterIP: !1,
  tsRasterWater: !1,
  tsRasterRoad: !1,
  tsSatLayer: !1,
  tsLayers: [],
}

const TsReducer = (s = inits, action) => {
  const {payload} = action
  switch (action.type) {
    case '_TS':
      return { ...s, [payload[0]]: payload[1] }

    case 'SET_TS_STATE':
      return { ...s,
        y:payload.y,
        sat:payload.sat
      }

    case '_TS_ADD_DYNAMIC_LAYER':
      return {
        ...s,
        tsLayers: [
          ...s.tsLayers,
          payload,
        ]
      }
    
    case '_TS_REMOVE_DYNAMIC_LAYER_BY_ID':
      return {
        ...s,
        tsLayers: s.tsLayers.filter((tl) => tl.id !== payload),
      }

    default:
      return s;
  }
};
export default TsReducer
