import moment from 'moment'

let gDt = new Date(),
dpS = gDt.getMonth() === 0 && gDt.getDate() <= 28 ? gDt.getFullYear() -  2 + '-01-01' : gDt.getFullYear() -  1 + '-01-01',
dpE = moment().subtract(14, "days").format('YYYY-MM-DD'),
dSD = gDt.getMonth() === 0 && gDt.getDate() <= 28 ? moment().subtract(100, "days").format('YYYY-MM-DD') : gDt.getFullYear() + '-01-01';

const inits = {
  raddStartDate: dSD,
  raddEndDate: dpE,
  raddUnconfirm: true,
  dpS,
  dpE
}

const RaddReducer = (s = inits, action) => {
  const {payload} = action
  switch (action.type) {
    case '_RADD':
      return { ...s, [payload[0]]: payload[1] }

    case 'SET_RADD_STATE':
      return { ...s,
        raddStartDate: payload[0],
        raddEndDate: payload[1],
        raddUnconfirm: payload[2]
      }
    case '_RADD_CHECK_MAX':
      if(s.raddEndDate > payload){
        return { ...s,
          raddEndDate: payload,
          raddStartDate: payload < s.raddStartDate ? moment(payload).subtract(14, "days").format('YYYY-MM-DD') : s.raddStartDate
        }
      }else{return s;}

    case '_RADD_ANS':
      return { ...s,
        raddStartDate: s.dpS > payload[0] ? s.dpS : payload[0],
        raddEndDate: payload[1],
        dpE: s.dpE < payload[1] ? payload[1] : s.dpE
      }

    // case '_RADD_FROM_LINK':
    //   return { ...s,...payload}

    default:
      return s;
  }
};
export default RaddReducer
