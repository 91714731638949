const inits = {
  isPin: 1
}

const PinReducer = (s = inits, action) => {
  const {payload} = action
  switch (action.type) {
    case '_PIN':
      return { ...s, [payload[0]]: payload[1] }

    case '_PIN_TOGGLE':
      return { ...s, isPin: !s.isPin }

    case '_PIN_OPEN':
      return { ...s, isPin: 1 }
    case '_PIN_CLOSE':
      return { ...s, isPin: !1 }

    default:
      return s;
  }
};
export default PinReducer
