import moment from 'moment'

const inits = {
  gtlayer: !1,
  // show: !1,
  showFull: !1,
  gtparams: 'id > 0',
  gtdata: [],
  from: moment('1973-01-01').format('YYYY-MM-DD'),
  to: moment().format('YYYY-MM-DD')
}

const GeoTagReducer = (s = inits, action) => {
  const {payload} = action
  switch (action.type) {
    case '_GEOTAG':
      return { ...s, [payload[0]]: payload[1] }

    case '_GEOTAG_TOGGLE':
      return { ...s, show: !s.show }

    // case '_GEOTAG_SHOW':
    //   return !s.show ? { ...s, show: 1 } : s
    //
    // case '_GEOTAG_HIDE':
    //   return s.show ? { ...s, show: !1 } : s

    default:
      return s;
  }
};
export default GeoTagReducer
