import moment from 'moment'

let gDt = new Date(),
dpS = gDt.getMonth() === 0 && gDt.getDate() <= 28 ? gDt.getFullYear() -  2 + '-01-01' : gDt.getFullYear() -  1 + '-01-01',
dpE = moment().subtract(14, "days").format('YYYY-MM-DD'),
dSD = gDt.getMonth() === 0 && gDt.getDate() <= 28 ? moment().subtract(100, "days").format('YYYY-MM-DD') : gDt.getFullYear() + '-01-01';

const inits = {
  gladStartDate: dSD,
  gladEndDate: dpE,
  gladUnconfirm: true,
  dpS,
  dpE
}

const GladReducer = (s = inits, action) => {
  const {payload} = action
  switch (action.type) {
    case '_GLAD':
      return { ...s, [payload[0]]: payload[1] }

    case 'SET_GLAD_STATE':
      return { ...s,
        gladStartDate: payload[0],
        gladEndDate: payload[1],
        gladUnconfirm: payload[2]
      }
    case '_GLAD_CHECK_MAX':
      if(s.gladEndDate > payload){
        return { ...s,
          gladEndDate: payload,
          gladStartDate: payload < s.gladStartDate ? moment(payload).subtract(14, "days").format('YYYY-MM-DD') : s.gladStartDate
        }
      }else{return s;}

    case '_GLAD_ANS':
      return { ...s,
        gladStartDate: s.dpS > payload[0] ? s.dpS : payload[0],
        gladEndDate: payload[1],
        dpE: s.dpE < payload[1] ? payload[1] : s.dpE
      }

    // case '_GLAD_FROM_LINK':
    //   return { ...s,...payload}

    default:
      return s;
  }
};
export default GladReducer
